
import {Vue, Component, Ref} from "vue-property-decorator";
import { Filter } from "@/cms-services/filter";
import FileUploaderDialog from "@/components/helpers/file-uploader-dialog.vue";
import {mdiPlusCircleOutline, mdiUpload} from "@mdi/js";
import DataList from "@/components/common/data/list.vue";
import {ProductModificationListDataSource} from "@/data/List/ProductModificationListDataSource";

@Component({
  components: {FileUploaderDialog}
})
export default class Home extends Vue {
  @Ref("dataList") dataList!: DataList;
  $message:any
  uploaderDialogVisible: boolean = false;
  icons: any = {
    circlePlus: mdiPlusCircleOutline,
    upload: mdiUpload,
  };
  loading:boolean = false;
  async uploadFile(event: any) {
    try {
      this.loading = true;
      await this.dataSource.uploadCsv(event, +this.$route.params.productId);
      await this.dataList.refreshData();
      this.$message("Успешно загружено");
    } catch (error) {
      this.$message((error as any)?.response?.data, "error");
    } finally {
      this.uploaderDialogVisible = false;
      this.loading = false;
    }
  }
  dataSource: ProductModificationListDataSource = new ProductModificationListDataSource({
      pageIndex: 1,
      pageSize: 15,
      filter: JSON.stringify([
        new Filter("productId", +this.$route.params.productId),
      ]),
  });

  model: any = {
    productId: +this.$route.params.productId,
    caption: "",
  };

  getRote = (item) => ({
    name: "product-modification-id",
    params: {
      productId: +this.$route.params.productId,
      id: item.id,
    },
  });

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
        exact: true,
      },
      {
        text: "Товары",
        to: "/manage/commodities",
        exact: true,
      },
      {
        text: this.$route.params.productId,
        to: `/manage/commodities/${this.$route.params.productId}/`,
        exact: true,
      },
      {
        text: "Модификации",
        disabled: true,
      },
    ];
  }
}
